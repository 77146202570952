<template>
    <v-container grid-list-md text-xs-center fluid>
        <h1>Pagamento</h1>
        <v-divider class="mb-4"></v-divider>
        <v-layout wrap v-if="service">
            <v-flex xs12>
                <v-text-field :label="$t('Identificação do Atendimento')" :value="service.id" readonly />
                <v-text-field :label="$t('Unidade')" :value="service.store" readonly />
                <v-text-field :label="$t('Cliente')" :value="service.client" readonly />
                <v-text-field :label="$t('Convênio')" :value="service.partner" v-if="service.partner" readonly />
            </v-flex>

            <v-flex xs12>
                <AditionalsPrice :id="id" :aditionalsPrice="aditionalsPrice" @update="update()" />
            </v-flex>

            <v-flex xs12 sm6>
                <v-card :color="remaining == 0 ? 'success' : 'grey'" dark>
                    <v-card-title class="text-h5"> {{ priceFormatted(service.totalPrice) }} </v-card-title>
                    <v-card-subtitle>Total</v-card-subtitle>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card :color="remaining == 0 ? 'success' : 'grey'" dark>
                    <v-card-title class="text-h5"> {{ priceFormatted(service.discount) }} </v-card-title>
                    <v-card-subtitle>Desconto</v-card-subtitle>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card :color="remaining == 0 ? 'success' : 'grey'" dark>
                    <v-card-title class="text-h5"> {{ priceFormatted(payedValue) }} </v-card-title>
                    <v-card-subtitle>Valor Pago</v-card-subtitle>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card :color="remaining == 0 ? 'success' : 'grey'" dark>
                    <v-card-title class="text-h5"> {{ priceFormatted(remaining) }} </v-card-title>
                    <v-card-subtitle>Falta Pagar</v-card-subtitle>
                </v-card>
            </v-flex>

            <v-flex xs12>
                <Pay :id="id" :max="remaining" @update="update()" v-if="remaining > 0" />
            </v-flex>

            <v-flex xs12>
                <v-btn color="info" dark style="width: 100%" @click="print()"> <v-icon dark left>print</v-icon> Imprimir recibo </v-btn>
            </v-flex>

            <v-flex xs12 v-if="service.payments && service.payments.length > 0" class="elevation-12 ma-6">
                <v-data-table :headers="headers" :items="service.payments" :items-per-page="-1" class="elevation-1" hide-default-footer>
                    <template v-slot:[`item.createdAt`]="{ item }">
                        {{ $moment(item.createdAt).format("DD/MM/YYYY HH:mm") }}
                    </template>
                    <template v-slot:[`item.value`]="{ item }">
                        {{ priceFormatted2(item) }}
                    </template>
                </v-data-table>
            </v-flex>

            <v-flex xs12 sm6>
                <v-btn color="grey" dark style="width: 100%" @click="returnScreen()"> <v-icon dark left>keyboard_return</v-icon> Sair </v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Pay from "./Pay";
import AditionalsPrice from "./AditionalsPrice";
import { mapGetters } from "vuex";

export default {
    components: { Pay, AditionalsPrice },

    data() {
        return {
            id: null,
            service: null,

            isFinancial: false,

            result: {
                edit: false,
                show: false,
                examPlans: null,
                idService: null,
                idExam: null,
            },

            headers: [
                { text: "Método", value: "method", sortable: true },
                { text: "Data", value: "createdAt", sortable: true },
                { text: "Valor", value: "value", align: "center", sortable: false },
            ],
        };
    },

    computed: {
        ...mapGetters(["verifyRole"]),
        payedValue() {
            return this.service && this.service.payments ? this.service.payments.reduce((sum, i) => Number(sum) + Number(i.enable == true ? i.value : 0), 0) : 0;
        },
        remaining() {
            return Number(Number(this.service.totalPrice) + Number(this.aditionalsPrice) - Number(this.service.discount) - Number(this.payedValue)).toFixed(2);
        },
        aditionalsPrice() {
            return Number(this.service && this.service.aditionalsPrice ? this.service.aditionalsPrice : 0);
        },
    },

    methods: {
        priceFormatted2(item) {
            return item.enable == true ? `R$ ${Number(item.value).toFixed(2)}` : `R$ ${Number(item.value).toFixed(2)} (Cancelado)`;
        },
        priceFormatted(price) {
            return `R$ ${Number(price).toFixed(2)}`;
        },
        update() {
            this.$http
                .get("service/payment/" + this.id)
                .then((result) => {
                    this.service = result;
                })
                .catch(() => {
                    this.service = null;
                });
        },

        returnScreen() {
            this.$router.go(-1);
            // this.$router.replace({ path: `/` });
        },

        print() {
            this.$http
                .getFile(`service/ticket/print/${this.id}`, `reciboAtendimento(${this.id})(${this.service.client})(${this.$moment().format("YYYYMMDD HHmmss")})`)
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao gerar PDF."));
                });
        },
    },

    mounted() {
        this.isFinancial = this.verifyRole("FINANCIAL");

        if (this.$route.params.id) {
            this.id = this.$route.params.id;
        }
        this.update();
    },
};
</script>